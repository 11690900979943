import React from 'react';
import PageNotFound from '../Images/error404_1.png';
import { Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
    let navigate = useNavigate();

    return (
        <div style={{textAlign:"center"}}>
            <Image fluid src={PageNotFound} alt="Not Found" />
            <p style={{textAlign:"center"}}>
                <Button variant="primary" size="lg" className="home"  onClick={() => navigate("/")}>
                    Go to Homepage
                </Button>
            </p>
        </div>
    );
}