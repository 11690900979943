import React, {useState} from 'react';
import {Button, ButtonGroup, Col, Container, Row, Image, Navbar, Modal} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

export default function FrontPage() {
    let navigate = useNavigate();

    const [showOne, setShowOne] = useState(false);
    const [showTwo, setShowTwo] = useState(false);

    const handleCloseOne = () => setShowOne(false);
    const handleShowOne = () => setShowOne(true);

    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);

    // <Button variant="primary" size="lg" className="app fnc shadow" onClick={() => navigate("/app")}>
    //    App
    // </Button>

    return (
        <Container fluid>
            <Modal size="lg" show={showOne} onHide={handleCloseOne} className="popupPug">
                <Modal.Body>
                    <Image fluid src={require('../Images/xPug_Render_1_USE_THIS.png')} alt="xPug Render" onClick={handleCloseOne}></Image>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showTwo} onHide={handleCloseTwo} className="popupPug">
                <Modal.Body>
                    <Image fluid src={require("../Images/xPug_Render_2.png")} alt="xPug Render" onClick={handleCloseTwo}></Image>
                </Modal.Body>
            </Modal>
            <Row className="body">
                <Col className="navBtns">
                    <ButtonGroup vertical className="d-flex">
                        <Button active variant="primary" size="lg" className="home fnc shadow">
                            Home
                        </Button>
                        <Button variant="primary" size="lg" className="gallery fnc shadow" onClick={() => navigate("/gallery")}>
                            NFT Gallery
                        </Button>
                        <Button variant="primary" size="lg" className="FAQ fnc shadow" onClick={() => navigate("/faq")}>
                            FAQ
                        </Button>
                    </ButtonGroup>

                    <ButtonGroup vertical className="helper">
                        <Button disabled variant="primary" size="lg" className="deco shadow">:)</Button>
                        <Button disabled variant="primary" size="lg" className="deco shadow">:)</Button>
                    </ButtonGroup>
                    <Button disabled variant="primary" size="lg" className="deco shadow">:)-</Button>
                </Col>
                <Col sm md lg xl xxl={7} className="mid shadow">
                    <Row sm md lg xl xxl={2} className="aboutContent">
                        <Col>
                            <div className="aboutOne">
                                <h1 className="aboutHeader">About:</h1>

                                <p className="aboutBody">xPug offers users the ability
                                    to earn rewards & tokens by
                                    interacting with their virtual pet.
                                    <br /><br />
                                    Dress up your pug with dozens
                                    of accessories, play minigames
                                    to earn rewards, and enter design
                                    contests to win prizes!</p>
                            </div>

                            <div className="aboutTwo">
                                <h1 className="tokenHeader">Tokens:</h1>
                            </div>
                        </Col>
                        <Col><Image fluid src={require('../Images/xPug_Render_1_USE_THIS.png')} alt="xPug Render" onClick={handleShowOne} className="pugPop"></Image></Col>
                    </Row>

                    <Row sm md lg xl xxl={2} className="tokenContent">
                        <Col>
                            <p className="tokenBody">
                                There are 2000 xPug tokens in existance,
                                which are redeemable (10:1) for xPug NFTs.
                                <br /><br />
                                10 xPug tokens = 1 xPug NFT
                                <br /><br />
                                Tokens used during the exchange will be burned.
                            </p>

                            <h1 className="nftHeader">NFTs:</h1>
                        </Col>
                        <div className="convertBack">
                            <Image fluid src={require("../Images/Conversion_Chart.png")} alt="token conversion chart" className="convertChart"></Image>
                        </div>
                    </Row>

                    <Row sm md lg xl xxl={2} className="nftContent">
                        <Col sm md lg xl xxl={6}>
                            <p className="nftBody">
                                There will be 200 redeemable xPug NFTs.
                                <br /><br />
                                -100 premade designs
                                <br /><br />
                                -50 unique community made designs (2 of each)
                                <br /><br />
                                There are 2 of each community made designs.
                                This is to reward the creator of the design, and
                                to allow the community that voted for it to
                                obtain one as well. Both will be uniquely marked.
                            </p>
                        </Col>

                        <Col sm md lg xl xxl={4} className="nftImg">
                            <Image fluid src={require("../Images/xPug_Render_2.png")} alt="token conversion chart" onClick={handleShowTwo} className="pugRender2"></Image>
                        </Col>
                    </Row>

                    <Row sm md lg xl xxl={1} className="imgFooter">
                        <Image fluid src={require("../Images/NFT_Chart_BG.png")} alt="token conversion chart"></Image>
                    </Row>

                </Col>
                <Col className="emptySpace">
                    <Button disabled variant="primary" size="lg" className="deco-r shadow" >:)-</Button>
                    <ButtonGroup vertical className="d-flex">
                        <Button variant="primary" size="lg" className="whitepaper fnc shadow" onClick={() => window.open("https://drive.google.com/file/d/1BCgGk0BW0aLWhDvJcV44SalmBL-sXgZ6/view")}>
                            Whitepaper
                        </Button>
                        <Button variant="primary" size="lg" className="trustline fnc shadow" onClick={() => window.open("https://xrpl.services/?issuer=rNxNMb7h5ZT86EXy6nHtxSpf4W8ZMLNy6b&currency=7850756700000000000000000000000000000000&limit=1999.659833896786")}>
                            Trustline
                        </Button>
                        <Button variant="primary" size="lg" className="twitter fnc shadow" onClick={() => window.open("https://twitter.com/xpug_xrpl")}>
                            Twitter
                        </Button>

                        <Navbar fixed="bottom">
                            <Container>
                                <Image fluid className="onXRP" src={require('../Images/listed_on_onxrp.png')} alt="Listed on XRP" onClick={() => window.open("https://xmc.onxrp.com/projects/XPUG")}></Image>
                            </Container>
                        </Navbar>
                    </ButtonGroup>
                </Col>
            </Row>
        </Container>
    );
}