import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import FrontPage from './components/FrontPage';
import Gallery from './components/Gallery';
import AppPage from './components/AppPage';
import NotFoundPage from './components/NotFoundPage';
import Header from './components/Header';
import FAQ from './components/FAQ';

import './App.css';

export default function App() {

    // <Route exact path="/app" element={<AppPage />} />

    return (
        <div>
            <Header />
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route exact path="/" element={<FrontPage />} />
                    <Route exact path="/gallery" element={<Gallery />} />
                    <Route exact path="/faq" element={<FAQ />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}
