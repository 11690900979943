import React from 'react';
import {Button, ButtonGroup, Col, Container, Row, Image, Navbar} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

export default function FAQ() {
    let navigate = useNavigate();

    // <Button variant="primary" size="lg" className="app fnc shadow" onClick={() => navigate("/app")}>
    //     App
    // </Button>

    return (
        <Container fluid>
            <Row className="body">
                <Col className="navBtns">
                    <ButtonGroup vertical className="d-flex">
                        <Button variant="primary" size="lg" className="home fnc shadow" onClick={() => navigate("/")}>
                            Home
                        </Button>
                        <Button variant="primary" size="lg" className="gallery fnc shadow" onClick={() => navigate("/gallery")}>
                            NFT Gallery
                        </Button>
                        <Button active variant="primary" size="lg" className="FAQ fnc shadow">
                            FAQ
                        </Button>
                    </ButtonGroup>

                    <ButtonGroup vertical className="helper">
                        <Button disabled variant="primary" size="lg" className="deco shadow">:)</Button>
                        <Button disabled variant="primary" size="lg" className="deco shadow">:)</Button>
                    </ButtonGroup>
                    <Button disabled variant="primary" size="lg" className="deco shadow">:)-</Button>
                </Col>
                <Col sm md lg xl xxl={7} className="mid shadow">
                    <Row>
                        <Col className="FAQOne">
                            <div>
                                <h2 className="FAQOneHeader">What is xPug?</h2>
                                <p className="FAQOneContent">xPug is an app, token, and NFT series.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="FAQTwo">
                            <div>
                                <h2 className="FAQTwoHeader">What is the xPug Companion App?</h2>
                                <p className="FAQTwoContent">The xPug Companion App is a virtual environment where users can interact with
                                    their pet to earn rewards and xPug tokens. Users will be able to play minigames,
                                    dress up their pug, and compete in design contests for a grand prize!
                                    The app will be playable on both desktop and mobile devices.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="FAQThree">
                            <div>
                                <h2 className="FAQThreeHeader">What are xPug tokens?</h2>
                                <p className="FAQThreeContent">The xPug tokens are issued on the XRP Ledger and are primarily used
                                    as an incentive for users playing the app. These tokens can be bought and sold
                                    on decentralized exchanges (DEX), but their main use is to be burned to receive
                                    xPug NFTs (with the arrival of XLS-20d).</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="FAQFour">
                            <div>
                                <h2 className="FAQFourHeader">What are xPug NFTs?</h2>
                                <p className="FAQFourContent">The xPug NFTs are a series of images themed around a variety of goofy subjects.
                                    There will be 200 xPug NFTs redeemable using xPug tokens. Half of these designs
                                    are created by us, and the other half are community design contest winners.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="FAQFive">
                            <div>
                                <h2 className="FAQFiveHeader">How can I get xPug tokens?</h2>
                                <p className="FAQFiveContent">You can get xPug tokens by participating in giveaways on our Twitter, having
                                    your user submitted design picked (Nov. 2021 submissions only), and soon
                                    you will be able to receive tokens for winning design contests and using the
                                    xPug Companion App.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="FAQSix">
                            <div>
                                <h2 className="FAQSixHeader">How many tokens for an xPug NFT?</h2>
                                <p className="FAQSixContent">To get 1 xPug NFT, it will cost 10 xPug tokens.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="FAQSeven">
                            <div>
                                <h2 className="FAQSevenHeader">How many tokens are there in total?</h2>
                                <p className="FAQSevenContent">There are 2000 xPug tokens in existance and there will never be any more.</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="FAQEight">
                            <div>
                                <h2 className="FAQEightHeader">When will the app be released?</h2>
                                <p className="FAQEightContent">The app will have a beta in Q2/Q3 2022, with the full release following
                                    in the months after.</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col className="emptySpace">
                    <Button disabled variant="primary" size="lg" className="deco-r shadow" >:)-</Button>
                    <ButtonGroup vertical className="d-flex">
                        <Button variant="primary" size="lg" className="whitepaper fnc shadow" onClick={() => window.open("https://drive.google.com/file/d/1BCgGk0BW0aLWhDvJcV44SalmBL-sXgZ6/view")}>
                            Whitepaper
                        </Button>
                        <Button variant="primary" size="lg" className="trustline fnc shadow" onClick={() => window.open("https://xrpl.services/?issuer=rNxNMb7h5ZT86EXy6nHtxSpf4W8ZMLNy6b&currency=7850756700000000000000000000000000000000&limit=1999.659833896786")}>
                            Trustline
                        </Button>
                        <Button variant="primary" size="lg" className="twitter fnc shadow" onClick={() => window.open("https://twitter.com/xpug_xrpl")}>
                            Twitter
                        </Button>

                        <Navbar fixed="bottom">
                            <Container>
                                <Image fluid className="onXRP" src={require('../Images/listed_on_onxrp.png')} alt="Listed on XRP" onClick={() => window.open("https://xmc.onxrp.com/projects/XPUG")}></Image>
                            </Container>
                        </Navbar>
                    </ButtonGroup>
                </Col>
            </Row>
        </Container>
    );
}