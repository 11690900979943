import React, { useState } from 'react';
import {Button, ButtonGroup, Card, Col, Container, Image, Modal, Navbar, Row} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import NFT1 from '../Images/NFTs/xPug_NFT_0-1.png';
import NFT2 from '../Images/NFTs/xPug_NFT_0-2.png';
import NFT3 from '../Images/NFTs/xPug_NFT_0-3.png';
import NFT4 from '../Images/NFTs/xPug_NFT_0-4.png';
import NFT5 from '../Images/NFTs/xPug_NFT_0-5.png';


export default  function Gallery() {
    let navigate = useNavigate()

    const [showOne, setShowOne] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [showFour, setShowFour] = useState(false);
    const [showFive, setShowFive] = useState(false);

    const handleCloseOne = () => setShowOne(false);
    const handleShowOne = () => setShowOne(true);
    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);
    const handleCloseThree = () => setShowThree(false);
    const handleShowThree = () => setShowThree(true);
    const handleCloseFour = () => setShowFour(false);
    const handleShowFour = () => setShowFour(true);
    const handleCloseFive = () => setShowFive(false);
    const handleShowFive = () => setShowFive(true);

    // <Button variant="primary" size="lg" className="app fnc shadow" onClick={() => navigate("/app")}>
    //      App
    // </Button>


    return (
            <Container fluid>
                <Modal size="lg" show={showOne} onHide={handleCloseOne} className="popupPug">
                    <Modal.Body>
                        <Image fluid src={NFT1} alt="xPug Render" onClick={handleCloseOne}></Image>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={showTwo} onHide={handleCloseTwo} className="popupPug">
                    <Modal.Body>
                        <Image fluid src={NFT2} alt="xPug Render" onClick={handleCloseTwo}></Image>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={showThree} onHide={handleCloseThree} className="popupPug">
                    <Modal.Body>
                        <Image fluid src={NFT3} alt="xPug Render" onClick={handleCloseThree}></Image>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={showFour} onHide={handleCloseFour} className="popupPug">
                    <Modal.Body>
                        <Image fluid src={NFT4} alt="xPug Render" onClick={handleCloseFour}></Image>
                    </Modal.Body>
                </Modal>
                <Modal size="lg" show={showFive} onHide={handleCloseFive} className="popupPug">
                    <Modal.Body>
                        <Image fluid src={NFT5} alt="xPug Render" onClick={handleCloseFive}></Image>
                    </Modal.Body>
                </Modal>
                <Row className="body">
                    <Col className="navBtns">
                        <ButtonGroup vertical className="d-flex">
                            <Button variant="primary" size="lg" className="home fnc shadow" onClick={() => navigate("/")}>
                                Home
                            </Button>
                            <Button active variant="primary" size="lg" className="gallery fnc shadow">
                                NFT Gallery
                            </Button>
                            <Button variant="primary" size="lg" className="faq fnc shadow" onClick={() => navigate("/faq")}>
                                FAQ
                            </Button>
                        </ButtonGroup>

                        <ButtonGroup vertical className="helper">
                            <Button disabled variant="primary" size="lg" className="deco shadow">:)</Button>
                            <Button disabled variant="primary" size="lg" className="deco shadow">:)</Button>
                        </ButtonGroup>
                        <Button disabled variant="primary" size="lg" className="deco shadow">:)-</Button>
                    </Col>
                    <Col sm md lg xl xxl={7} className="mid shadow">
                        <Row xs={3} md={3} className="g-4">
                            <Col>
                                <Card className="pug">
                                    <Card.Img src={NFT1} onClick={handleShowOne}></Card.Img>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="pug">
                                    <Card.Img src={NFT2} onClick={handleShowTwo}></Card.Img>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="pug">
                                    <Card.Img src={NFT3} onClick={handleShowThree}></Card.Img>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="pug">
                                    <Card.Img src={NFT4} onClick={handleShowFour}></Card.Img>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="pug">
                                    <Card.Img src={NFT5} onClick={handleShowFive}></Card.Img>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="emptySpace">
                        <Button disabled variant="primary" size="lg" className="deco-r shadow" >:)-</Button>
                        <ButtonGroup vertical className="d-flex">
                            <Button variant="primary" size="lg" className="whitepaper fnc shadow" onClick={() => window.open("https://drive.google.com/file/d/1BCgGk0BW0aLWhDvJcV44SalmBL-sXgZ6/view")}>
                                Whitepaper
                            </Button>
                            <Button variant="primary" size="lg" className="trustline fnc shadow" onClick={() => window.open("https://xrpl.services/?issuer=rNxNMb7h5ZT86EXy6nHtxSpf4W8ZMLNy6b&currency=7850756700000000000000000000000000000000&limit=1999.659833896786")}>
                                Trustline
                            </Button>
                            <Button variant="primary" size="lg" className="twitter fnc shadow" onClick={() => window.open("https://twitter.com/xpug_xrpl")}>
                                Twitter
                            </Button>

                            <Navbar fixed="bottom">
                                <Container>
                                    <Image fluid className="onXRP" src={require('../Images/listed_on_onxrp.png')} alt="Listed on XRP" onClick={() => window.open("https://xmc.onxrp.com/projects/XPUG")}></Image>
                                </Container>
                            </Navbar>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>
        );
}
