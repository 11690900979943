import React from "react";
import { Row, Container, Image } from "react-bootstrap";
import xPugRibbon from "../Images/xPug_Website_Ribbon.png"

export default function Header() {
    return (
        <Container fluid>
            <Row className="banner shadow-lg"><h1>Welcome to xPug.</h1></Row>
            <Image className="ribbon" src={xPugRibbon} alt="xPug Ribbon"/>
        </Container>);
}